import React from 'react'
import Typography from "@material-ui/core/Typography"

const ArticleList = ({ordered = false, start, children, variant = "body1"}) => {

    if (ordered) {
        return (
            <Typography component="ol" variant={variant}>{children}</Typography>
        )
    }

    return (
        <Typography component="ul" variant={variant}>{children}</Typography>
    )

}

export default ArticleList

