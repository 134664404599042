import React from 'react'
import Typography from "@material-ui/core/Typography"

const ArticleParagraph = ({children, variant = 'body1', component = 'h2', level}) => {

    if (level) {
        variant = 'h'+level
        component = variant
    }

    return (
        <Typography component="p" variant={variant}>{children}</Typography>
    )

}

export default ArticleParagraph

