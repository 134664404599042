import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    article: {
        backgroundColor: props => { return props.backgroundColor },
        display: "flex",
        flexDirection: "column",
        width: '100%',
    }
}));

const ArticleBase = ({children, ...props}) => {

    const classes = useStyles(props)

    return (
        <article className={classes.article}>
            {children}
        </article>
    )

}


ArticleBase.defaultProps = {
}

export default ArticleBase
