import React from 'react'
import Typography from "@material-ui/core/Typography"

const ArticleHeading = ({children, variant = 'h2', component = 'h2', level}) => {

    if (level) {
        variant = 'h'+level
        component = variant
    }

    return (
        <Typography component="h1" variant={variant}>{children}</Typography>
    )

}

export default ArticleHeading

