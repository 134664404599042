import React from 'react'
import Typography from "@material-ui/core/Typography"
import ReactMarkdown from 'react-markdown'

import ArticleHeading from "./ArticleHeading"
import ArticleParagraph from "./ArticleParagraph"
import ArticleLink from "./ArticleLink"

import ArticleList from "./ArticleList"
import ArticleListItem from "./ArticleListItem"

const renderers = {
    "heading": ArticleHeading,
    "paragraph": ArticleParagraph,
    "link": ArticleLink,
    "list": ArticleList,
//    "listItem": ArticleListItem
}


const ArticleContent = ({ children }) => <ReactMarkdown renderers={renderers} children={children}  />

export default ArticleContent
