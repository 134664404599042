import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    body: {
        display: "flex",
        flexDirection: "column",
    }
}));

const ArticleBody = ({children}) => {

    const classes = useStyles()

    return (
        <div className={classes.body}>
            {children}
        </div>
    )

}


ArticleBody.defaultProps = {
}

export default ArticleBody
